import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128059056295'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Cest": [
      "",
      "39 / 98",
      "41 / 104",
      "43 3/4 / 111",
      "46 1/2 / 118",
      "",
      "",
      "49 1/4 / 125",
      "52 / 132"
    ],
    "Length": [
      "",
      "26 3/4 / 68",
      "27 1/2 / 70",
      "28 1/4 / 72",
      "29 / 74",
      "",
      "",
      "30 / 76",
      "30 3/4 / 78"
    ]
  }


  return (
    <Layout>
      <SEO title="Shop - Baggage Tag Sweatshirt Man" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify